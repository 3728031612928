body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.agent-page {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.agent-page img {
    /* width: 600px; */
    max-width: 200px;
    margin: auto;
    margin-top: 20px;
}

.message {
    color: black;
}

.nav-tabs > .nav-item > .nav-link {
  color: #212529 !important;
}

input[type='range']::-webkit-slider-thumb {
  background-color: var(--thumb-color);
  width: var(--thumb-width);
  height: var(--thumb-height);
  transform: translateY(var(--thumb-translatey)); /* Move the thumb up */
}

input[type='range']::-moz-range-thumb {
  background-color: var(--thumb-color);
  width: var(--thumb-width);
  height: var(--thumb-height);
  transform: translateY(var(--thumb-translatey)); /* Move the thumb up */
}

.timeline {
  border-left: 1px solid hsl(0, 0%, 90%);
  position: relative;
  list-style: none;
}

.timeline .timeline-item {
  position: relative;
}

.timeline .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .timeline-item:after {
  background-color: var(--dot-color); 
  left: -38px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
}

.toast-container-mobile {
  bottom: 60px !important;
}

.toast-container-desktop {
  bottom: 20px !important;
}

.nav-underline .nav-item .nav-link {
  color: #65676b !important;
  font-size: var(--nav-link-font-size);
  font-weight: 500;
}

.nav-underline .nav-item .nav-link.active {
  border-bottom: solid 2px var(--nav-link-active-border-color) !important;
  color: var(--nav-link-active-color) !important;
  font-size: var(--nav-link-font-size);
  font-weight: 500;
}
.custom-switch .form-check-input {
  border-color: var(--agent-colour) !important;
}

.custom-switch .form-check-input:checked {
  background-color: var(--agent-colour) !important;
  border-color: var(--agent-colour) !important;
}

.custom-switch .form-check-input:focus {
  box-shadow: 0 0 0 0.2rem rgba(var(--agent-colour-rgb), 0.25) !important;
}

.custom-switch .form-check-input::before {
  background-color: var(--agent-colour) !important;
  border-color: var(--agent-colour) !important;
}

.custom-switch .form-check-input:checked::before {
  background-color: #fff !important; /* Ensure the toggle circle is visible when checked */
  border-color: var(--agent-colour) !important;
}

.custom-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23D3D3D3'/%3e%3c/svg%3e") !important;
}

.custom-switch .form-check-input:checked {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}

.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item:focus {
  background-color: var(--dropdown-active-bg) !important;
}